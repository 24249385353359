import i18n from 'i18next';
import { AviaPassengerType } from '@websky/graphql';
import { RouteType } from './types';
import { SPECIAL_CATEGORIES } from '../SearchForm/utils';
import { PassengerType } from '../enums';
import { getPassengerTypeLabel, initI18n } from '../utils';
import { format } from '../utils';
export const getRouteType = (segments) => {
    let result = RouteType.ComplexRoute;
    if (segments.length === 1) {
        result = RouteType.OneWay;
    }
    else if (segments.length === 2 && segments[0].departure.iata === segments[1].arrival.iata) {
        result = RouteType.RoundTrip;
    }
    return result;
};
export const getPassengersCount = (passengers) => {
    return passengers.reduce((result, passenger) => result + passenger.count, 0);
};
initI18n('QuickSearchForm');
export const getPassengersSummary = (passengers) => {
    const parsedPassengers = [];
    let numOfInfants = 0;
    const specialCategoriesMap = new Map();
    passengers.forEach(passenger => {
        var _a;
        // Count all types of infants.
        if (passenger.passengerType === AviaPassengerType.INS || passenger.passengerType === AviaPassengerType.INF) {
            numOfInfants += passenger.count;
        }
        else if (SPECIAL_CATEGORIES.includes(passenger.extendedPassengerType)) {
            let passengerType = passenger.extendedPassengerType;
            if (passengerType === PassengerType.RetireeF) {
                passengerType = PassengerType.RetireeM;
            }
            specialCategoriesMap.set(passengerType, ((_a = specialCategoriesMap.get(passengerType)) !== null && _a !== void 0 ? _a : 0) + passenger.count);
        }
        else {
            parsedPassengers.push(passenger);
        }
    });
    specialCategoriesMap.forEach((count, type) => {
        parsedPassengers.push({
            passengerType: AviaPassengerType.ADT,
            extendedPassengerType: type,
            count
        });
    });
    if (numOfInfants) {
        parsedPassengers.push({
            passengerType: AviaPassengerType.INF,
            extendedPassengerType: null,
            count: numOfInfants
        });
    }
    const filterPassengers = parsedPassengers.filter(passenger => passenger.count > 0);
    if (filterPassengers.length >= 3) {
        const count = getPassengersCount(filterPassengers);
        return [`${count} ${i18n.t('QuickSearchForm:passenger', { count })}`];
    }
    return filterPassengers.map(passenger => {
        if (passenger.extendedPassengerType) {
            console.log('here');
            console.log(passenger);
            return `${passenger.count} ${i18n.t(`QuickSearchForm:${passenger.extendedPassengerType}`, {
                count: passenger.count
            })}`;
        }
        return `${passenger.count} ${getPassengerTypeLabel(passenger.passengerType, passenger.extendedPassengerType, passenger.count)}`;
    });
};
export const getDatesSummary = (segments) => {
    let prevDate = null;
    const parsedDates = [];
    for (const segment of segments) {
        const date = new Date(segment.date);
        if (prevDate) {
            parsedDates.push(`${format(prevDate, prevDate.getMonth() !== date.getMonth() ? 'dd MMM' : 'dd')}`);
        }
        prevDate = date;
    }
    parsedDates.push(format(prevDate, 'dd MMM'));
    return parsedDates;
};
