import * as React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Inner from './Inner';
import { useGraphQLClient } from '../../graphql-client';
import Analytics from './Analytics';
import ExternalLocaleResources from '../../ExternalLocaleResources/ExternalLocaleResources';
import IsDemo from './IsDemo';
import AccessDeniedError from './AccessDeniedError';
import GoogleOneTapSignIn from './GoogleOneTapSignIn';
import { useVarioqub, VarioqubContext } from '../../Varioqub/varioqub';
const Engine = ({ before, after }) => {
    const client = useGraphQLClient();
    const varioqub = useVarioqub();
    return (React.createElement(Router, null,
        React.createElement(Analytics, null),
        React.createElement(IsDemo, null),
        React.createElement(AccessDeniedError, null),
        React.createElement(ApolloProvider, { client: client },
            React.createElement(ExternalLocaleResources, null),
            React.createElement(GoogleOneTapSignIn, null),
            before,
            React.createElement(VarioqubContext.Provider, { value: varioqub },
                React.createElement(Inner, null)),
            after)));
};
export default Engine;
