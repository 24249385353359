export const isPassengersHeightExceedViewport = (passengersRect) => {
    const segmentTabs = document.getElementById('segment-tabs');
    const toolbar = document.getElementById('toolbar');
    if (!segmentTabs || !toolbar) {
        return false;
    }
    const viewPort = toolbar.getBoundingClientRect().top - segmentTabs.getBoundingClientRect().bottom;
    const margin = 40;
    return passengersRect.height + margin > viewPort;
};
