import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './FareGroupGridRulesButton.css';
import Button from '@websky/core/src/Button';
import Modal from '@websky/core/src/Modal';
import CompareFares from '@websky/core/src/CompareFares';
const FareGroupGridRulesButton = ({ flightId }) => {
    const { t } = useTranslation('FlightInfoModalContent');
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.container },
            React.createElement(Button, { className: css.button__rules, variant: "text", onClick: onOpen }, t('View full conditions'))),
        React.createElement(Modal, { open: isOpen, onClose: onClose, closeClassName: css.modal__close },
            React.createElement("div", { className: css.modal__header }, t('View full conditions')),
            React.createElement(CompareFares, { code: '', flightId: flightId }))));
};
export default FareGroupGridRulesButton;
