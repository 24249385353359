import { __rest } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import SearchFormPromoCode from '../../../SearchForm/components/SearchForm/Bottom/PromoCode/PromoCode';
import { usePromoCode } from '../../../PromoCode/hooks';
import { PromoCodeStatus } from '../../../PromoCode/types';
const QuickSearchFormPromoCode = props => {
    var _a;
    const { status } = usePromoCode(props.isPromoCodeApplied ? PromoCodeStatus.Applied : PromoCodeStatus.Empty);
    const handleSubmit = useCallback((searchParams) => {
        props.onSubmit(searchParams);
    }, [status, props.onSubmit]);
    const handleClear = useCallback((searchParams) => {
        const { promotionCode } = searchParams, searchParamsWithoutPromoCode = __rest(searchParams, ["promotionCode"]);
        props.onSubmit(searchParamsWithoutPromoCode);
    }, [status, props.onSubmit]);
    return (React.createElement(SearchFormPromoCode, { promoCode: props.promoCode, onClose: props.onClose, onOpen: props.onOpen, onAddPromoCode: handleSubmit, status: (_a = props.status) !== null && _a !== void 0 ? _a : status, onClearPromoCode: handleClear, isFormOpen: props.isFormOpen }));
};
export default QuickSearchFormPromoCode;
