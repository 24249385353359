import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CheckoutAdditionalServiceBase from '@websky/core/src/CheckoutAdditionalService/CheckoutAdditionalService/CheckoutAdditionalService';
import Money from '@websky/core/src/Money';
import Button from '@websky/core/src/Button';
import css from './CheckoutAdditionalService.css';
const PlusIcon = (React.createElement("svg", { width: "21", height: "21", viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M16.625 11.375H11.375V16.625H9.625V11.375H4.375V9.625H9.625V4.375H11.375V9.625H16.625V11.375Z", fill: "currentColor" })));
const CheckoutAdditionalService = props => {
    const { t } = useTranslation('Checkout');
    const renderControls = React.useCallback(({ buttons, isSelected, isLoading, addClick, showAddButton }) => {
        var _a;
        return (React.createElement("div", { className: css.controls },
            isSelected &&
                (buttons === null || buttons === void 0 ? void 0 : buttons.length) > 0 &&
                buttons.map(button => {
                    var _a;
                    return (React.createElement(Button, { key: button.text, className: button.className, isLoading: button.isLoading, onClick: () => button.action(), variant: (_a = button.variant) !== null && _a !== void 0 ? _a : 'text', size: "common" }, button.text));
                }),
            !isSelected && showAddButton !== false && (React.createElement(React.Fragment, null,
                ((_a = props.priceFrom) === null || _a === void 0 ? void 0 : _a.amount) > 0 && (React.createElement("div", { className: css.controls__price },
                    t('from'),
                    React.createElement(Money, { money: props.priceFrom }))),
                React.createElement(Button, { variant: "outline", className: cn(css.button, css.button_plus), disabled: isLoading, onClick: addClick }, PlusIcon)))));
    }, [props.priceFrom]);
    return React.createElement(CheckoutAdditionalServiceBase, Object.assign({}, props, { renderControls: renderControls }));
};
export default CheckoutAdditionalService;
