import * as React from 'react';
import { useContext } from 'react';
import RootComponent from '@websky/core/src/SeatMap/components/SeatMapService/SeatMapService';
import { ModeContext } from '@websky/core/src/context';
import { Mode } from '@websky/core/src/types';
export var ArmeniaSeatColors;
(function (ArmeniaSeatColors) {
    ArmeniaSeatColors[ArmeniaSeatColors["Free"] = 0] = "Free";
    ArmeniaSeatColors[ArmeniaSeatColors["Expensive"] = 1] = "Expensive";
    ArmeniaSeatColors[ArmeniaSeatColors["Price1"] = 2] = "Price1";
    ArmeniaSeatColors[ArmeniaSeatColors["Price2"] = 3] = "Price2";
    ArmeniaSeatColors[ArmeniaSeatColors["Price3"] = 4] = "Price3";
    ArmeniaSeatColors[ArmeniaSeatColors["Price4"] = 5] = "Price4";
    ArmeniaSeatColors[ArmeniaSeatColors["Price5"] = 6] = "Price5";
    ArmeniaSeatColors[ArmeniaSeatColors["CheckinPaid"] = 7] = "CheckinPaid";
})(ArmeniaSeatColors || (ArmeniaSeatColors = {}));
const RM_SEAT_COLORS = [
    ArmeniaSeatColors.Price1,
    ArmeniaSeatColors.Price2,
    ArmeniaSeatColors.Price3,
    ArmeniaSeatColors.Price4
];
export const SeatMapColorsContext = React.createContext(null);
const SeatMap = props => {
    const mode = useContext(ModeContext);
    const colors = React.useMemo(() => {
        var _a, _b;
        const seatMapColors = {};
        const seatPrices = new Set();
        (_b = (_a = props.seatMaps) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.forEach(segment => {
            var _a, _b, _c;
            const segmentColors = new Map();
            let maxPrice = 0;
            (_c = (_b = (_a = segment.decks) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.rows) === null || _c === void 0 ? void 0 : _c.forEach(row => {
                row.seats.forEach(seat => {
                    var _a;
                    if (((_a = seat.price) === null || _a === void 0 ? void 0 : _a.amount) > 0) {
                        seatPrices.add(seat.price.amount);
                        if (seat.price.amount > maxPrice) {
                            maxPrice = seat.price.amount;
                        }
                    }
                });
            });
            segmentColors.set(0, ArmeniaSeatColors.Free);
            if (maxPrice > 0) {
                segmentColors.set(maxPrice, ArmeniaSeatColors.Expensive);
            }
            [...seatPrices.values()]
                .filter(price => price > 0 && price !== maxPrice)
                .sort((a, b) => a - b)
                .forEach((price, index) => {
                segmentColors.set(price, mode === Mode.Checkin
                    ? ArmeniaSeatColors.CheckinPaid
                    : index < RM_SEAT_COLORS.length
                        ? RM_SEAT_COLORS[index]
                        : RM_SEAT_COLORS[RM_SEAT_COLORS.length - 1]);
            });
            seatMapColors[segment.segmentsInfo.id] = segmentColors;
        });
        return seatMapColors;
    }, [props.seatMaps, mode]);
    return (React.createElement(SeatMapColorsContext.Provider, { value: colors },
        React.createElement(RootComponent, Object.assign({}, props))));
};
export default SeatMap;
