import * as React from 'react';
import RootComponent from '@websky/core/src/SeatMap/components/SeatMap/Seat/Seat';
import { SeatMapColorsContext, ArmeniaSeatColors } from '../SeatMap';
import css from './Seat.css';
const colorsClass = {
    [ArmeniaSeatColors.Free]: css.seat_free,
    [ArmeniaSeatColors.Expensive]: css.seat_expensive,
    [ArmeniaSeatColors.CheckinPaid]: css.seat_checkin,
    [ArmeniaSeatColors.Price1]: css.seat_1,
    [ArmeniaSeatColors.Price2]: css.seat_2,
    [ArmeniaSeatColors.Price3]: css.seat_3,
    [ArmeniaSeatColors.Price4]: css.seat_4
};
const Seat = props => {
    var _a, _b;
    const colorsContext = React.useContext(SeatMapColorsContext);
    const color = props.isAvailable ? (_a = colorsContext === null || colorsContext === void 0 ? void 0 : colorsContext[props.segmentId]) === null || _a === void 0 ? void 0 : _a.get((_b = props.seat.price) === null || _b === void 0 ? void 0 : _b.amount) : null;
    return React.createElement(RootComponent, Object.assign({}, props, { className: colorsClass[color] }));
};
export default Seat;
