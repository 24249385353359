import { __awaiter } from "tslib";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { getAvailableInsuranceCodesByPrograms, getAvailableSelectedInsuranceCodes, getImportantInsuranceCodes, getOrder } from '../Checkout/store/order/selectors';
import { getTotalPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { fillOrder } from '../Checkout/store/order/actions';
import { Mode } from '../types';
import { Currency } from '../enums';
import { getCheckinOrder } from '../Modules/Checkin/store/order/selectors';
import { getSelectedInsuranceSaveCodes } from './utils';
import { saveOrderServicesParamsFactory } from './store/utils';
import { useInsuranceUpsaleModal } from './components/InsuranceUpsaleModal/InsuranceUpsaleModal.hook';
import { ModeContext, useConfig } from '../context';
import { get, set, sessionStorageGet, sessionStorageSet, IMPORTANT_INSURANCE_TOUCHED } from '../cache';
import { useToggleable } from '../hooks';
export const useInsurancePageWrapper = ({ strategy, insurancePrograms }) => {
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const orderPriceToPay = useSelector(getTotalPriceToPay);
    const availableInsuranceCodes = useSelector(getAvailableInsuranceCodesByPrograms(insurancePrograms));
    const selectedInsuranceCodes = useSelector(getAvailableSelectedInsuranceCodes(availableInsuranceCodes));
    const importantInsuranceCodes = useImportantInsurancesTouched();
    const { global: { insurance: { allowImplicitConfirm } } } = useConfig();
    const [isNeedToSendSaveRequest, setIsNeedToSendSaveReq] = useState(false);
    const { isOpen: isShowImportantInsurancesError, open: onShowImportantInsurancesError, close: onHideImportantInsurancesError } = useToggleable(false);
    const [saveOrderServices, mutationResult] = useSaveOrderServicesMutation();
    const [saveOrderServicesParams, setSaveOrderServicesParams] = useState();
    const selectedSaveServicesCodes = useMemo(() => {
        return getSelectedInsuranceSaveCodes(saveOrderServicesParams, availableInsuranceCodes);
    }, [saveOrderServicesParams, availableInsuranceCodes]);
    const insuranceCodesToSave = useMemo(() => {
        return [...new Set([...selectedSaveServicesCodes, ...selectedInsuranceCodes])];
    }, [selectedSaveServicesCodes, selectedInsuranceCodes]);
    const insuranceUpsaleModal = useInsuranceUpsaleModal({
        strategy,
        availableInsuranceCodes,
        selectedInsuranceCodes: insuranceCodesToSave
    });
    useEffect(() => {
        var _a;
        if ((_a = mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.data) === null || _a === void 0 ? void 0 : _a.SaveOrderServices) {
            dispatch(fillOrder(mutationResult.data.SaveOrderServices));
        }
    }, [mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.data]);
    const [insurancePriceToPay, setInsurancePriceToPay] = useState({
        amount: 0,
        currency: Currency.RUB
    });
    const isNeedSelectImportantInsurances = useMemo(() => {
        if (!allowImplicitConfirm) {
            return false;
        }
        return !importantInsuranceCodes.isAllImportantInsurancesTouched;
    }, [allowImplicitConfirm, importantInsuranceCodes.isAllImportantInsurancesTouched]);
    const saveOrderServicesWithParams = useCallback((codes) => {
        const travellerIds = order.travellers.map(traveller => traveller.id);
        const saveOrderParams = saveOrderServicesParamsFactory(travellerIds, codes);
        return saveOrderServices({
            variables: {
                params: Object.assign(Object.assign({}, saveOrderParams.params), { id: order.id })
            }
        });
    }, [order.travellers, saveOrderServices]);
    const saveOrderServicesRequest = useCallback((codes = []) => {
        const selectedCodesSet = new Set([...codes, ...insuranceCodesToSave]);
        return saveOrderServicesWithParams([...selectedCodesSet]);
    }, [insuranceCodesToSave, saveOrderServicesWithParams]);
    const handleAgree = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield insuranceUpsaleModal.onAgree((insuranceCodes) => __awaiter(void 0, void 0, void 0, function* () {
            if (!(insuranceCodes === null || insuranceCodes === void 0 ? void 0 : insuranceCodes.length)) {
                return;
            }
            yield saveOrderServicesRequest(insuranceCodes);
        }));
    }), [saveOrderServicesRequest, insuranceUpsaleModal.onAgree]);
    const handleDisagree = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield insuranceUpsaleModal.onDisagree(() => __awaiter(void 0, void 0, void 0, function* () {
            if (!isNeedToSendSaveRequest) {
                return;
            }
            yield saveOrderServicesRequest();
        }));
    }), [isNeedToSendSaveRequest, saveOrderServicesRequest, insuranceUpsaleModal.onDisagree]);
    const saveOrderServicesCallback = useCallback((callback) => __awaiter(void 0, void 0, void 0, function* () {
        if (isNeedSelectImportantInsurances) {
            onShowImportantInsurancesError();
            return;
        }
        yield insuranceUpsaleModal.onContinue(() => __awaiter(void 0, void 0, void 0, function* () {
            if (isNeedToSendSaveRequest) {
                yield saveOrderServicesRequest();
            }
            callback();
        }));
    }), [
        isNeedToSendSaveRequest,
        saveOrderServicesRequest,
        isNeedSelectImportantInsurances,
        insuranceUpsaleModal.onContinue
    ]);
    return {
        // methods
        saveOrderServices,
        saveOrderServicesRequest,
        saveOrderServicesWithParams,
        saveOrderServicesCallback,
        setInsurancePriceToPay,
        setIsNeedToSendSaveReq,
        setSaveOrderServicesParams,
        onHideImportantInsurancesError,
        closeUpsaleModal: insuranceUpsaleModal.onCloseUpsaleModal,
        onContinue: insuranceUpsaleModal.onContinue,
        handleAgree,
        handleDisagree,
        // data
        order,
        orderId: order.id,
        orderPriceToPay,
        insurancePriceToPay,
        mutationResult,
        saveOrderServicesParams,
        selectedSaveServicesCodes,
        priorityInsurance: insuranceUpsaleModal.priorityInsurance,
        isNeedToSendSaveRequest,
        isUpsaleOpen: insuranceUpsaleModal.isUpsaleOpen,
        isLoading: mutationResult.loading,
        isShowImportantInsurancesError
    };
};
function getStrategyKey(strategy, orderId) {
    return `${strategy}_${orderId}`;
}
export const useInsuranceUpsaleConfig = () => {
    const updateId = useRef(0);
    const order = useSelector(getOrder);
    const { global: { insurance: { upsaleStrategy } } } = useConfig();
    const [shownStrategies, unShownStrategies] = useMemo(() => {
        const shown = [];
        const unShown = [];
        upsaleStrategy.forEach(strategyKey => {
            const existKey = get(`${strategyKey}_${order.id}`);
            if (existKey) {
                shown.push(strategyKey);
            }
            else {
                unShown.push(strategyKey);
            }
        });
        return [shown, unShown];
    }, [upsaleStrategy, order.id, updateId.current]);
    const getIsNeedToShow = useCallback((strategy) => {
        return upsaleStrategy.includes(strategy) && unShownStrategies.includes(strategy);
    }, [upsaleStrategy, unShownStrategies]);
    const onApplyStrategy = useCallback((strategy) => {
        set(getStrategyKey(strategy, order.id), true);
        updateId.current++;
    }, [order.id, updateId.current]);
    const onCancelStrategy = useCallback((strategy) => {
        set(getStrategyKey(strategy, order.id), false);
        updateId.current++;
    }, [order.id, updateId.current]);
    return {
        // data
        upsaleStrategy,
        shownStrategies,
        unShownStrategies,
        // methods
        getIsNeedToShow,
        onApplyStrategy,
        onCancelStrategy
    };
};
export const useImportantInsurancesTouched = () => {
    var _a;
    const mode = useContext(ModeContext);
    let order;
    let importantInsuranceCodes = [];
    if (mode === Mode.Checkin) {
        order = useSelector(getCheckinOrder);
    }
    else {
        order = useSelector(getOrder);
        importantInsuranceCodes = useSelector(getImportantInsuranceCodes);
    }
    const importantInsurancesTouchedKey = `${IMPORTANT_INSURANCE_TOUCHED}_${order.id}`;
    const importantInsurancesTouchedCodes = (_a = sessionStorageGet(importantInsurancesTouchedKey)) !== null && _a !== void 0 ? _a : [];
    const isAllImportantInsurancesTouched = useMemo(() => {
        if (!importantInsuranceCodes.length) {
            return true;
        }
        return importantInsuranceCodes.every(code => importantInsurancesTouchedCodes.includes(code));
    }, [importantInsuranceCodes, importantInsurancesTouchedCodes]);
    // Save in the session the parameter of the touched important
    // insurance to display pressed radio buttons in the SelectForm
    const onTouchInsurance = useCallback((code) => {
        if (importantInsuranceCodes.includes(code)) {
            const codes = [...new Set([...importantInsurancesTouchedCodes, code])];
            sessionStorageSet(importantInsurancesTouchedKey, codes);
        }
    }, [order.id, importantInsuranceCodes, importantInsurancesTouchedCodes]);
    const getIsTouched = useCallback((code) => importantInsurancesTouchedCodes.includes(code), [
        importantInsurancesTouchedCodes
    ]);
    return {
        // data
        importantInsurancesTouchedCodes,
        isAllImportantInsurancesTouched,
        // methods
        onTouchInsurance,
        getIsTouched
    };
};
