import { TravellerFieldEnum } from '@websky/graphql';
import { clearPhone } from '../../../BaseComponents/PhoneInput/utils';
import { concatPassengerNames, getUserValue } from '../../../utils';
export const getFormTravellerKey = (id) => `t${id}`;
export const getTotalPrice = (values, price) => {
    const totalPrice = Object.assign(Object.assign({}, price), { amount: 0 });
    Object.keys(values).forEach(key => {
        if (values[key].selected) {
            totalPrice.amount = totalPrice.amount + price.amount;
        }
    });
    return totalPrice;
};
export const getUpdatedSms = (values, smsNotificationService, dirtyFields) => {
    const changedValues = [];
    Object.keys(values).forEach(key => {
        if (dirtyFields[`${key}.selected`]) {
            changedValues.push({
                count: values[key].selected ? 1 : 0,
                passengerId: values[key].id,
                segmentIds: smsNotificationService.allowedSegments.map(segment => segment.toString()),
                serviceId: smsNotificationService.id,
                allowedSegments: smsNotificationService.allowedSegments
            });
        }
    });
    return changedValues;
};
export const getUpdatedTravellersPhones = (values, dirtyFields) => {
    const travellersValues = [];
    Object.keys(values).forEach(key => {
        if (values[key].selected && dirtyFields[`${key}.phone`]) {
            travellersValues.push({
                id: values[key].id,
                values: [
                    {
                        type: TravellerFieldEnum.Phone,
                        value: clearPhone(values[key].phone),
                        name: 'phone'
                    }
                ]
            });
        }
    });
    return travellersValues;
};
export const getTravellerLabel = (traveller) => {
    const lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
    const firstName = getUserValue(traveller, TravellerFieldEnum.FirstName);
    return concatPassengerNames(lastName, firstName);
};
