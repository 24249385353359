import { __awaiter } from "tslib";
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useInsurancePageWrapper } from '../../../InsurancePage/hooks';
import { getInsurances, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { getInsurancePriceToPay } from '../../../Checkout/store/orderPrice/selectors';
import { useToggleable } from '../../../hooks';
import { getMinPrice, getTotalPrice, initI18n } from '../../../utils';
import { analytics_removeInsurance } from '../../../Insurance/utils';
export const useInsuranceUpsale = (insurancePrograms) => {
    const insurancePageWrapper = useInsurancePageWrapper({ strategy: 'checkout-order-service', insurancePrograms });
    const isConfirmed = useSelector(getIsConfirmed);
    const insurances = useSelector(getInsurances);
    const insuranceTotalPrice = useSelector(getInsurancePriceToPay);
    const order = useSelector(getOrder);
    const { isOpen, open, close } = useToggleable(false);
    const availablePrograms = useMemo(() => {
        if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
            return insurances.insurancePrograms;
        }
        return insurances.insurancePrograms.filter(program => insurancePrograms.some(available => available.code === program.code));
    }, [insurances.insurancePrograms, insurancePrograms]);
    const selectedPrograms = useMemo(() => {
        var _a;
        const selectedProgramsMap = new Map();
        if ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
            insurances.selectedInsurances.forEach(insurance => {
                const insuranceCode = insurance.insuranceProgram.code;
                const isAvailableInsurance = availablePrograms.some(program => program.code === insuranceCode);
                // exclude duplicate selected insurances
                if (isAvailableInsurance && !selectedProgramsMap.has(insuranceCode)) {
                    selectedProgramsMap.set(insuranceCode, insurance);
                }
            });
        }
        return [...selectedProgramsMap.values()];
    }, [insurances.selectedInsurances, availablePrograms]);
    const insuranceSummaryServices = useMemo(() => {
        const additionalServices = insurancePageWrapper.order.additionalServices;
        return Object.assign(Object.assign({}, additionalServices), { insurances: Object.assign(Object.assign({}, additionalServices.insurances), { insurancePrograms: availablePrograms }) });
    }, [insurancePageWrapper.order.additionalServices, availablePrograms]);
    const totalPrice = useMemo(() => {
        if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
            return insuranceTotalPrice;
        }
        return getTotalPrice(selectedPrograms, insurance => insurance.insuranceProgram.totalPrice);
    }, [insurancePrograms, selectedPrograms]);
    const minPrice = useMemo(() => {
        return getMinPrice(availablePrograms, insurance => insurance.totalPrice);
    }, [insurances]);
    const isDifferentPrices = useMemo(() => new Set(availablePrograms.map(insurance => insurance.totalPrice.amount)).size > 1, [availablePrograms]);
    const singleInsurance = useMemo(() => {
        if (availablePrograms.length === 1) {
            return availablePrograms[0];
        }
        return null;
    }, [availablePrograms]);
    const onAddInsurance = useCallback((code) => __awaiter(void 0, void 0, void 0, function* () {
        const selectedProgramsSet = new Set();
        insurances.selectedInsurances.forEach(insurance => selectedProgramsSet.add(insurance.insuranceProgram.code));
        selectedProgramsSet.add(code);
        yield insurancePageWrapper.saveOrderServicesWithParams([...selectedProgramsSet]);
    }), [insurances.selectedInsurances, insurancePageWrapper.saveOrderServicesWithParams]);
    const onDeleteInsurance = useCallback((code) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const selectedProgramsSet = new Set();
        (_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.forEach(insurance => selectedProgramsSet.add(insurance.insuranceProgram.code));
        selectedProgramsSet.delete(code);
        const deletingProgram = (_b = insurances.selectedInsurances.find(selected => selected.insuranceProgram.code === code)) === null || _b === void 0 ? void 0 : _b.insuranceProgram;
        if (deletingProgram) {
            analytics_removeInsurance(deletingProgram, order.travellers);
        }
        yield insurancePageWrapper.saveOrderServicesWithParams([...selectedProgramsSet]);
    }), [insurances.selectedInsurances, insurancePageWrapper.saveOrderServicesWithParams]);
    const onAddSingle = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!singleInsurance) {
            return;
        }
        yield onAddInsurance(singleInsurance.code);
    }), [singleInsurance, onAddInsurance]);
    const onDeleteSingle = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!singleInsurance) {
            return;
        }
        yield onDeleteInsurance(singleInsurance.code);
    }), [singleInsurance, onDeleteInsurance]);
    const onConfirm = useCallback(() => insurancePageWrapper.saveOrderServicesCallback(close), [
        insurancePageWrapper.saveOrderServicesCallback
    ]);
    const onClose = useCallback(() => {
        insurancePageWrapper.onHideImportantInsurancesError();
        insurancePageWrapper.onContinue(close);
    }, [insurancePageWrapper.onHideImportantInsurancesError, insurancePageWrapper.onContinue]);
    const handleAgree = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (insurancePageWrapper.priorityInsurance) {
            yield insurancePageWrapper.handleAgree();
            insurancePageWrapper.closeUpsaleModal();
            close();
            return;
        }
        yield insurancePageWrapper.handleAgree();
    }), [insurancePageWrapper.priorityInsurance, insurancePageWrapper.handleAgree, close]);
    const handleDisagree = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield insurancePageWrapper.handleDisagree();
        close();
    }), [insurancePageWrapper.handleDisagree, close]);
    return {
        // methods
        open,
        handleAgree,
        handleDisagree,
        onConfirm,
        onClose,
        onAddInsurance,
        onDeleteInsurance,
        onAddSingle,
        onDeleteSingle,
        setInsurancePriceToPay: insurancePageWrapper.setInsurancePriceToPay,
        setSaveOrderServicesParams: insurancePageWrapper.setSaveOrderServicesParams,
        setIsNeedToSendSaveReq: insurancePageWrapper.setIsNeedToSendSaveReq,
        // data
        order: insurancePageWrapper.order,
        insurancePriceToPay: insurancePageWrapper.insurancePriceToPay,
        priorityInsurance: insurancePageWrapper.priorityInsurance,
        availablePrograms,
        selectedPrograms,
        insuranceSummaryServices,
        minPrice,
        totalPrice,
        singleInsurance,
        isOpen,
        isConfirmed,
        isDifferentPrices,
        isUpsaleOpen: insurancePageWrapper.isUpsaleOpen,
        isLoading: insurancePageWrapper.isLoading,
        isShowImportantInsurancesError: insurancePageWrapper.isShowImportantInsurancesError
    };
};
initI18n('Checkout');
export const useInsuranceGroupTexts = (groupName, insurancePrograms) => {
    var _a, _b, _c;
    const { t, i18n } = useTranslation('Checkout');
    const titleKey = `Checkout:InsuranceGroupTitle_${groupName}`;
    const descrKey = `Checkout:InsuranceGroupDescr_${groupName}`;
    const title = i18n.exists(titleKey) ? t(titleKey) : t('InsuranceGroupTitle_default');
    const descr = i18n.exists(descrKey) ? t(descrKey) : t('InsuranceGroupDescr_default');
    if ((insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length) === 1) {
        const singleInsurance = insurancePrograms[0];
        return {
            title: (_a = singleInsurance.name) !== null && _a !== void 0 ? _a : title,
            descr: (_b = singleInsurance.description) !== null && _b !== void 0 ? _b : descr,
            options: ((_c = singleInsurance.options) === null || _c === void 0 ? void 0 : _c.length) ? singleInsurance.options.map(({ name }) => name) : []
        };
    }
    return { title, descr, options: [] };
};
