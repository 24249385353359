import { __rest } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../theme';
import Summary from './Summary/Summary';
import SearchForm from '../../SearchForm';
import DummySummary from './DummySummary/DummySummary';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../utils';
import PromoCode from '../../PromoCode';
import { PromoCodeStatus } from '../../PromoCode/types';
import QuickSearchFormPromoCode from './QuickSearchFormPromoCode/QuickSearchFormPromoCode';
import { usePromoCode } from '../../PromoCode/hooks';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import { convertSearchParameters } from '../../Results/utils';
import { useDispatch } from 'react-redux';
import { clearPromoCode } from '../../SearchForm/store/promoCode/actions';
initI18n('QuickSearchForm');
const QuickSearchForm = ({ parameters, SearchFormProps = { onSubmit: undefined }, isDisabled = false, children, hidePromoCode, isPromoCodeApplied, isFlightChosen }) => {
    var _a;
    const { onSubmit } = SearchFormProps, restProps = __rest(SearchFormProps, ["onSubmit"]);
    const { QuickSearchForm: css } = useTheme('QuickSearchForm');
    const [isOpen, setIsOpen] = useState(false);
    const { isFormOpen: isPromoCodeFormOpen, setFormOpen, closeForm } = usePromoCode();
    const { global: { allowPromoCodes } } = useConfig();
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const dispatch = useDispatch();
    const handleClearPromocode = useCallback(() => {
        dispatch(clearPromoCode());
        handleSearchStart(convertSearchParameters(parameters, ''));
    }, [isPromoCodeApplied]);
    const handleOpenPromoCode = useCallback(() => {
        setIsOpen(true);
        setFormOpen(true);
    }, [isPromoCodeFormOpen, isPromoCodeApplied]);
    const handleClosePromoCode = useCallback(() => {
        setIsOpen(false);
        setFormOpen(false);
    }, [isPromoCodeFormOpen, isPromoCodeApplied]);
    const handleSearchStart = (params) => {
        if (onSubmit) {
            onSubmit(params);
        }
        setIsOpen(false);
    };
    React.useEffect(() => {
        setIsOpen(false);
        setFormOpen(false);
    }, [isFlightChosen]);
    const promoCodeStub = useMemo(() => (React.createElement(PromoCode, { className: css.promoCode, status: isPromoCodeApplied
            ? PromoCodeStatus.Applied
            : parameters.promotionCode
                ? PromoCodeStatus.NotApplied
                : PromoCodeStatus.Empty, promoCode: 'any promocode', isFormOpen: false, isLoading: false, onAddPromoCode: () => null, onOpenForm: handleOpenPromoCode, onCloseForm: closeForm, onClear: handleClearPromocode })), [isPromoCodeApplied]);
    const renderSearchFormPromoCode = useCallback(() => {
        if (hidePromoCode) {
            return null;
        }
        return (React.createElement(QuickSearchFormPromoCode, { status: isPromoCodeApplied
                ? PromoCodeStatus.Applied
                : parameters.promotionCode
                    ? PromoCodeStatus.NotApplied
                    : PromoCodeStatus.Empty, promoCode: parameters.promotionCode, onClose: handleClosePromoCode, onOpen: handleOpenPromoCode, isFormOpen: isPromoCodeFormOpen, onSubmit: handleSearchStart, isPromoCodeApplied: isPromoCodeApplied }));
    }, [hidePromoCode, isPromoCodeFormOpen, isPromoCodeApplied]);
    return (React.createElement("div", { className: css.outer },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.inner },
                isDisabled && React.createElement(DummySummary, null),
                !isDisabled && ((_a = parameters.segments) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: cn(css.outer__summary, { [css.outer__summary_open]: isOpen }) },
                        React.createElement("div", { className: css.inner__summary },
                            React.createElement(Summary, { parameters: parameters, onClick: toggle, isOpen: isOpen, className: css.summary, renderPromoCode: () => (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH, minWidth: MOBILE_MIN_WIDTH }, !isOpen && !hidePromoCode && promoCodeStub)) }),
                            React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 }, !isOpen && !hidePromoCode && promoCodeStub))),
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: isOpen },
                            React.createElement("div", { className: css.form },
                                React.createElement(OverrideComponent, { componentProps: Object.assign({ className: css.form__wrapper, renderPromoCode: renderSearchFormPromoCode, defaultParameters: parameters, onSubmit: handleSearchStart }, restProps), component: {
                                        SearchForm: SearchForm
                                    } })))),
                    React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                        allowPromoCodes && (React.createElement(Collapse, { in: !isOpen, unmountOnExit: true, mountOnEnter: true }, !hidePromoCode && React.createElement("div", { className: css.promoCode__wrp }, promoCodeStub))),
                        React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: isOpen },
                            React.createElement("div", { className: css.form },
                                React.createElement(OverrideComponent, { componentProps: Object.assign({ renderPromoCode: renderSearchFormPromoCode, defaultParameters: parameters, onSubmit: handleSearchStart }, restProps), component: {
                                        SearchForm: SearchForm
                                    } }))))))),
            React.createElement(Collapse, { in: !isOpen },
                React.createElement("div", { className: cn({
                        [css.children_hidden]: isOpen
                    }) }, children)))));
};
export default QuickSearchForm;
