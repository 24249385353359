import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../theme';
import { InfoMessageTypeEnum, CheckinOrderStatus, useCheckinRulesLazyQuery, useCheckinCreateOrderMutation, useResetOrderPaymentBlockMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import RetrieveBooking from '../../../../../RetrieveBooking';
import Modal from '../../../../../Modal';
import { fillCheckinOrder } from '../../../store/order/actions';
import CheckinRules from '../../../../../CheckinRules';
import { Mode } from '../../../../../RetrieveBooking/components/RetrieveBooking';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../../../../../context';
import { OverrideComponent } from '../../../../../renderProps';
import { useToggleable } from '../../../../../hooks';
import Conditions from '../ConditionsModal/Conditions/Conditions';
const Initial = ({ match, history }) => {
    const { Inital: css } = useTheme('Checkin');
    const { t } = useTranslation('Checkin');
    const dispatch = useDispatch();
    const params = match.params;
    const location = useLocation();
    const isAutoSubmit = location.pathname.includes('submit');
    const { isOpen: rulesModalIsOpen, open: openModal, close: closeModal } = useToggleable(false);
    const [createCheckin] = useCheckinCreateOrderMutation();
    const [resetOrderPaymentBlock] = useResetOrderPaymentBlockMutation();
    const [getCheckin, { data: rules }] = useCheckinRulesLazyQuery();
    const { refetch: getOrder } = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'no-cache' });
    const { global: { companyInfo: { iataCode } } } = useConfig();
    let order;
    const getCheckinRules = (orderId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield getCheckin({ variables: { id: orderId } });
        }
        catch (e) {
            console.error(e);
        }
    });
    const openCheckinPage = () => {
        if (order) {
            dispatch(fillCheckinOrder(order));
            history.push(`/${order.id}`);
        }
    };
    const onSumbit = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { data, errors } = yield createCheckin({ variables: { params } });
                let result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinCreateOrder);
                if (result === null || result === void 0 ? void 0 : result.isBlockedForPayment) {
                    yield resetOrderPaymentBlock({ variables: { id: result.id } });
                    const { data, errors } = yield getOrder({ id: result.id });
                    if (!errors && data.CheckinOrder) {
                        result = data.CheckinOrder;
                        resolve(data.CheckinOrder);
                    }
                }
                else {
                    resolve(data.CheckinCreateOrder);
                }
                if (result) {
                    order = result;
                    setTimeout(() => {
                        if (order.status === CheckinOrderStatus.Confirmed || iataCode === 'N4') {
                            openCheckinPage();
                        }
                        else if (!result.isBlockedForPayment) {
                            getCheckinRules(order.id).then(openModal);
                        }
                    });
                }
            }
            catch (e) {
                reject(e);
            }
        }));
    }), []);
    const acceptRules = useCallback(() => {
        closeModal();
        openCheckinPage();
    }, []);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content },
            React.createElement(Conditions, { className: css.conditions }),
            React.createElement(RetrieveBooking, { mode: Mode.Checkin, className: css.form, title: t('Online check-in'), initialId: params.PNR, initialSecret: params.lastName, submitWithInitialValues: isAutoSubmit, onSubmit: onSumbit, idTitle: t('Ticket number or booking reference'), secretTitle: t('Passenger last name'), hideSecretHint: true })),
        React.createElement(OverrideComponent, { componentProps: {
                infoMessage: {
                    code: null,
                    type: InfoMessageTypeEnum.Promo,
                    text: t('Для регистрации на рейс авиакомпании «Ямал», пожалуйста, посетите их официальный сайт')
                },
                type: 'global',
                status: InfoMessageTypeEnum.Promo,
                actionText: 'Перейти',
                onClickAction: () => window.open('https://yamal.aero/check-in/?wx#/')
            }, component: { CheckinNotification: () => null } }),
        React.createElement(OverrideComponent, { componentProps: { className: css.hint }, component: { renderCheckinHint: () => null } }),
        iataCode !== 'N4' && (React.createElement(Modal, { open: rulesModalIsOpen, onClose: closeModal, maxWidth: "md", classes: {
                container: css.modal,
                paper: css.modal__paper
            } }, !!rules && React.createElement(CheckinRules, { onSumbit: acceptRules, rules: rules.CheckinRules })))));
};
export default withRouter(Initial);
