import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '@websky/core/src/utils';
import SearchFormDatepicker from '@websky/core/src/SearchFormDatepicker/components/SearchFormDatepicker';
initI18n('SearchFormDatepicker');
const SearchFormDatePicker = props => {
    const { t } = useTranslation('SearchFormDatepicker');
    const formatPrice = (value) => {
        let formattedPrice = value;
        if (value.length > 6) {
            formattedPrice = value.slice(0, 3) + t('k.');
        }
        return formattedPrice;
    };
    return React.createElement(SearchFormDatepicker, Object.assign({}, props, { formatPrice: formatPrice }));
};
export default SearchFormDatePicker;
