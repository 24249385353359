import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Banner } from '../Banner/Banner';
import { Card } from '../Card/Card';
import { useInsuranceProduct } from './InsuranceProduct.hook';
import { useTheme } from '../../../theme';
import { IconButton } from '../../../index';
import { Add, Download } from '../../../Icons';
import { InsurancePrice } from '../InsurancePrice/InsurancePrice';
import { SelectForm } from '../SelectForm/SelectForm';
import Link from '../../../Link';
import { InfoIcon } from '../../../FlightCard/components/Icons';
import { OptionsList } from '../OptionsList/OptionsList';
export const InsuranceProduct = ({ onToggle, insuranceProgram, productState, className, popular, policyUrl, onSelect, onRemove, hideForAllPassengersTextBecauseSinglePassenger, isShowImportantInsurancesError }) => {
    var _a;
    const { t } = useTranslation('InsurancePage');
    const { InsuranceProduct: theme } = useTheme('InsurancePage');
    const model = useInsuranceProduct(insuranceProgram, onToggle, popular, productState);
    const infoLink = useMemo(() => {
        if (!insuranceProgram.urlToOffer && !model.isConfirmed) {
            return null;
        }
        return (React.createElement("div", { className: theme.linksWrapper },
            insuranceProgram.urlToOffer && (React.createElement(Link, { className: theme.moreInfo, action: insuranceProgram.urlToOffer, target: '_blank' },
                React.cloneElement(InfoIcon, {
                    className: theme.infoIcon
                }),
                t('More details'))),
            model.isConfirmed && policyUrl && (React.createElement(Link, { className: theme.moreInfo, action: policyUrl, target: '_blank' },
                React.cloneElement(Download, {
                    className: theme.infoIcon
                }),
                t('Download police')))));
    }, [insuranceProgram.urlToOffer, policyUrl, model.isConfirmed]);
    return (React.createElement(Card, { className: cn(theme.border, theme.shadow, {
            [theme.popular]: popular,
            [theme.confirmed]: model.isConfirmed
        }, className) },
        React.createElement(Banner, { className: theme.banner, code: insuranceProgram.code, popular: popular }),
        React.createElement("div", { className: theme.content },
            model.showIconButton && (React.createElement(IconButton, { onClick: model.handleToggle, isSuccess: model.isSelected || model.isConfirmed, className: cn(theme.selectButton, {
                    [theme.confirmed]: model.isConfirmed
                }) }, Add)),
            React.createElement("div", { className: theme.title }, insuranceProgram.name),
            insuranceProgram.description && (React.createElement("div", { className: theme.description, dangerouslySetInnerHTML: { __html: insuranceProgram.description } })),
            !!((_a = insuranceProgram.options) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(OptionsList, { className: theme.options, options: insuranceProgram.options.map(({ name }) => name) })),
            !popular && infoLink,
            popular && !model.isConfirmed && (React.createElement(SelectForm, { className: theme.formControl, onSelect: () => onSelect(insuranceProgram.code), onRemove: () => onRemove(insuranceProgram.code), selected: model.isSelected, price: insuranceProgram.totalPrice, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger, isTouched: model.isTouched, isError: model.isError && isShowImportantInsurancesError })),
            !popular && (React.createElement(InsurancePrice, { className: theme.footer, selected: model.isSelected, price: insuranceProgram.totalPrice, variant: popular ? 'mostPopular' : 'regular', onClick: !model.isConfirmed ? model.handleToggle : undefined, onRemove: model.handleToggle, hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })),
            popular && infoLink)));
};
