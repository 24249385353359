import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { arrowIcon, editIcon } from '../Icons';
import { useIsMobile } from '../../../utils';
const Edit = ({ className, onClick, isOpen = false }) => {
    const { Edit: css } = useTheme('Navigation');
    const { t } = useTranslation('QuickSearchForm');
    const isMobile = useIsMobile();
    return (React.createElement("button", { className: cn(css.button, className), onClick: onClick },
        React.createElement("span", { className: css.button__text }, t('Modify search')),
        React.createElement("span", { className: cn(css.button__icon, {
                [css.button__icon_reversed]: isOpen
            }) }, isMobile && !isOpen ? editIcon : arrowIcon)));
};
export default Edit;
