import { useEffect } from 'react';
import { getCurrency, setCurrency } from '@websky/core/src/cache';
export const useDefaultCurrency = (defaultCurrency) => {
    const currency = getCurrency();
    useEffect(() => {
        if (currency !== defaultCurrency) {
            setCurrency(defaultCurrency);
        }
    }, []);
    const onSubmit = (parameters, onSubmitHandler) => {
        if (parameters.currency !== currency && parameters.currency !== defaultCurrency) {
            onSubmitHandler({
                currency: defaultCurrency,
                passengers: parameters.passengers.map(passenger => ({
                    passengerType: passenger.passengerType,
                    extendedPassengerType: passenger.extendedPassengerType,
                    count: passenger.count
                })),
                segments: parameters.segments.map(segment => ({
                    date: segment.date,
                    departure: { iata: segment.departure.iata },
                    arrival: { iata: segment.arrival.iata }
                })),
                promotionCode: parameters.promotionCode || '',
                ffpMode: parameters.ffpMode || false
            });
        }
    };
    return { onSubmit };
};
