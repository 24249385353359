import { merge } from 'lodash';
import { getTravellersMap } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { mergeDuplicatedTravellerServices } from '../Checkout/utils';
// TODO: add treeshake support. https://stackoverflow.com/questions/58741044/why-webpack-doesnt-tree-shake-the-lodash-when-using-import-as
const getServiceKey = (service, gdsServices, type) => {
    var _a;
    if ((gdsServices === null || gdsServices === void 0 ? void 0 : gdsServices.length) && type === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge) {
        const gdsService = gdsServices.find(gdsService => gdsService.id === (service === null || service === void 0 ? void 0 : service.serviceId));
        return (gdsService === null || gdsService === void 0 ? void 0 : gdsService.rfisc) || (service === null || service === void 0 ? void 0 : service.serviceId);
    }
    return (service === null || service === void 0 ? void 0 : service.serviceId) || ((_a = service === null || service === void 0 ? void 0 : service.service) === null || _a === void 0 ? void 0 : _a.rfisc);
};
const calculateServicesBySegments = (services, gdsServices, type) => {
    const servicesIdsSegmentsCount = {};
    services.forEach(service => {
        var _a;
        const key = getServiceKey(service, gdsServices, type);
        const serviceBySegments = (_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.reduce((acc, segmentId) => {
            var _a, _b;
            const updatedAcc = Object.assign({}, acc);
            updatedAcc[segmentId] = {
                count: (_a = service.count) !== null && _a !== void 0 ? _a : 0,
                confirmedCount: (_b = service.confirmedCount) !== null && _b !== void 0 ? _b : 0
            };
            return updatedAcc;
        }, {});
        servicesIdsSegmentsCount[key] = merge(servicesIdsSegmentsCount[key], serviceBySegments);
    });
    return servicesIdsSegmentsCount;
};
const isServiceForAllSegments = (serviceSegmentsCount, segmentsCount) => {
    const countServicesForSegments = Object.values(serviceSegmentsCount || {});
    if (countServicesForSegments.length !== segmentsCount) {
        return false;
    }
    return (countServicesForSegments.length === segmentsCount &&
        countServicesForSegments.every(counter => counter.count === countServicesForSegments[0].count));
};
const collectServices = (servicesSegmentIds, traveller, segmentsCount, type, travellerId, gdsServices, collectType) => {
    var _a;
    const travellerAllSegmentsMap = new Map();
    const result = {};
    const isAdditional = collectType === 'additional';
    const travellerServices = mergeDuplicatedTravellerServices(isAdditional ? (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services : traveller.services.brandIncludedServices.services, service => {
        var _a, _b, _c;
        return `${(_b = (_a = service.service) === null || _a === void 0 ? void 0 : _a.rfisc) !== null && _b !== void 0 ? _b : service.serviceId}_${(_c = service.segmentIds) === null || _c === void 0 ? void 0 : _c.toString()}`;
    });
    const servicesSegmentsCounter = calculateServicesBySegments(travellerServices, gdsServices, type);
    servicesSegmentIds.forEach(segmentId => {
        var _a;
        const travellerSegments = [];
        travellerServices
            .filter(service => {
            var _a;
            if (!((_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.includes(segmentId))) {
                return false;
            }
            return isAdditional
                ? gdsServices === null || gdsServices === void 0 ? void 0 : gdsServices.some(gdsService => gdsService.id === service.serviceId)
                : service.service.type === type;
        })
            .forEach(service => {
            var _a;
            const key = getServiceKey(service, gdsServices, type);
            const segmentsCounter = servicesSegmentsCounter[key];
            const selectedService = service, gdsService = gdsServices.find(gdsService => gdsService.id === service.serviceId);
            if (selectedService.count > 0) {
                if (isServiceForAllSegments(segmentsCounter, segmentsCount)) {
                    travellerAllSegmentsMap.set(key, [
                        ...(travellerAllSegmentsMap.get(key) || []),
                        {
                            serviceId: selectedService.serviceId,
                            count: selectedService.count,
                            confirmedCount: selectedService.confirmedCount,
                            products: selectedService.products,
                            service: isAdditional ? gdsService : service.service
                        }
                    ]);
                }
                else if ((_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.includes(segmentId)) {
                    travellerSegments.push({
                        serviceId: selectedService.serviceId,
                        count: segmentsCounter[segmentId].count,
                        confirmedCount: segmentsCounter[segmentId].confirmedCount,
                        products: selectedService.products,
                        service: isAdditional ? gdsService : service.service
                    });
                }
            }
        });
        result[travellerId] = Object.assign(Object.assign({}, result[travellerId]), { [segmentId]: {
                [collectType]: travellerSegments
            }, allSegments: Object.assign(Object.assign({}, (_a = result[travellerId]) === null || _a === void 0 ? void 0 : _a.allSegments), { [collectType]: [...travellerAllSegmentsMap.values()].map(services => (Object.assign(Object.assign({}, services[0]), { prices: services.map(service => service.service.price) }))) }) });
    });
    return result;
};
export const groupServices = (additionalServices, traveller, travellerId, type, flightSegments, filter = () => true, customFilter) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const filterAdditionalServices = (_b = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(service => {
        if (customFilter) {
            return customFilter(service);
        }
        return service.type === type && filter(service);
    });
    const additionalServicesSegmentIds = new Set();
    (_e = (_d = (_c = traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(service => {
        service.segmentIds.forEach(id => additionalServicesSegmentIds.add(id));
    });
    const includedServicesSegmentIds = new Set();
    (_h = (_g = (_f = traveller.services) === null || _f === void 0 ? void 0 : _f.brandIncludedServices) === null || _g === void 0 ? void 0 : _g.services) === null || _h === void 0 ? void 0 : _h.forEach(service => {
        service.segmentIds.forEach(id => includedServicesSegmentIds.add(id));
    });
    let result = {};
    const additional = collectServices(additionalServicesSegmentIds, traveller, flightSegments.length, type, travellerId, filterAdditionalServices, 'additional');
    result = Object.assign(Object.assign({}, result), additional);
    const included = collectServices(includedServicesSegmentIds, traveller, flightSegments.length, type, travellerId, [], 'included');
    return merge(result, included);
};
export const filterServicesByRfisc = (traveller, services) => {
    var _a, _b;
    const additionalKeys = new Set();
    (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.forEach(service => {
        service.segmentIds.forEach(segmentId => {
            var _a;
            const rfisc = (_a = services.gdsServices.services.find(item => item.id === service.serviceId)) === null || _a === void 0 ? void 0 : _a.rfisc;
            additionalKeys.add(`${rfisc}_${segmentId}`);
        });
    });
    traveller.services.brandIncludedServices.services = (_b = traveller.services.brandIncludedServices.services) === null || _b === void 0 ? void 0 : _b.filter(service => {
        return service.segmentIds.some(segmentId => {
            var _a;
            return !((_a = service.service.isReplacedBy) === null || _a === void 0 ? void 0 : _a.some(rfisc => {
                const additionalKey = `${rfisc}_${segmentId}`;
                return additionalKeys.has(additionalKey);
            }));
        });
    });
    return traveller;
};
export const groupSeats = (traveller) => {
    var _a, _b, _c, _d, _e;
    const travellerId = traveller.id;
    const additionalSeats = {
        [travellerId]: {}
    };
    const segmentAdditionalSeatsMap = new Map();
    (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.forEach(seat => {
        const item = segmentAdditionalSeatsMap.get(seat.segment.id);
        if (item) {
            segmentAdditionalSeatsMap.set(seat.segment.id, [...item, seat]);
        }
        else {
            segmentAdditionalSeatsMap.set(seat.segment.id, [seat]);
        }
    });
    // additional seats
    (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.seats) === null || _d === void 0 ? void 0 : _d.forEach(seat => {
        additionalSeats[travellerId] = Object.assign(Object.assign({}, additionalSeats[travellerId]), { [seat.segment.id]: {
                additional: segmentAdditionalSeatsMap.get(seat.segment.id) || [],
                included: []
            } });
    });
    const includedServicesSegmentIds = new Set();
    (_e = traveller.services.brandIncludedServices) === null || _e === void 0 ? void 0 : _e.services.filter(service => service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat).forEach(service => {
        service.segmentIds.forEach(id => includedServicesSegmentIds.add(id));
    });
    const includedSeats = {
        [travellerId]: {}
    };
    // included seats
    includedServicesSegmentIds.forEach(segmentId => {
        const travellerIncludedSegments = [];
        traveller.services.brandIncludedServices.services
            .filter(service => service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat)
            .forEach(service => {
            travellerIncludedSegments.push(service);
        });
        includedSeats[travellerId] = Object.assign(Object.assign({}, includedSeats[travellerId]), { [segmentId]: Object.assign(Object.assign({}, includedSeats[travellerId][segmentId]), { included: travellerIncludedSegments }) });
    });
    return merge(includedSeats, additionalSeats);
};
export const getSegmentsById = (segments) => {
    const segmentsById = new Map();
    segments.forEach(({ segment }) => {
        segmentsById.set(segment.id, {
            id: segment.id,
            iataDirection: `${segment.departure.airport.iata} — ${segment.arrival.airport.iata}`
        });
    });
    return segmentsById;
};
export const createCartContext = (travellers, segments, prices) => {
    const segmentsById = new Map();
    segments.forEach(({ segment }) => {
        segmentsById.set(segment.id, {
            id: segment.id,
            iataDirection: `${segment.departure.airport.iata} — ${segment.arrival.airport.iata}`
        });
    });
    return {
        travellersById: getTravellersMap(travellers),
        segmentsById,
        totalPrices: {
            baggage: prices === null || prices === void 0 ? void 0 : prices.baggage,
            flights: prices === null || prices === void 0 ? void 0 : prices.airticket,
            seats: prices === null || prices === void 0 ? void 0 : prices.seats,
            meal: prices === null || prices === void 0 ? void 0 : prices.meals,
            total: prices === null || prices === void 0 ? void 0 : prices.total,
            aeroexpress: prices === null || prices === void 0 ? void 0 : prices.aeroexpress,
            insurance: prices === null || prices === void 0 ? void 0 : prices.insurance,
            businessLounge: prices === null || prices === void 0 ? void 0 : prices.businessLounge,
            animals: prices === null || prices === void 0 ? void 0 : prices.animals,
            transfer: prices === null || prices === void 0 ? void 0 : prices.transfer,
            priorityBoarding: prices === null || prices === void 0 ? void 0 : prices.priorityBoarding,
            sms: prices === null || prices === void 0 ? void 0 : prices.sms
        }
    };
};
export const getPassengerValuesMap = (traveller) => {
    const valuesMap = new Map();
    traveller.values.forEach(value => {
        valuesMap.set(value.type, value.value);
    });
    return valuesMap;
};
