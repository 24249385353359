import * as React from 'react';
export const FareLockIcon = (React.createElement("svg", { width: 77, height: 76, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#prefix__clip0_12547:40410)" },
        React.createElement("circle", { cx: 38.5, cy: 38.5, r: 38, transform: "rotate(-45 38.5 38.5)", fill: "#EDF1F8" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.917 34.333v-6.416a4.583 4.583 0 119.166 0v6.416h-9.166zM38.5 19.667a8.25 8.25 0 018.25 8.25v6.416h5.5v22h-27.5v-22h5.5v-6.416a8.25 8.25 0 018.25-8.25z", fill: "#2652AE" }),
        React.createElement("path", { d: "M40.085 47.536c0-.44-.118-.788-.356-1.046-.233-.263-.63-.504-1.194-.724-.564-.22-1.05-.437-1.458-.652a4.91 4.91 0 01-1.054-.748 3.133 3.133 0 01-.682-.998c-.16-.38-.24-.834-.24-1.36 0-.906.278-1.65.837-2.229.558-.579 1.3-.917 2.225-1.014v-1.721h1.24v1.746c.915.134 1.63.53 2.148 1.19.517.655.775 1.505.775 2.551h-2.24c0-.643-.13-1.124-.388-1.44-.254-.322-.595-.483-1.024-.483-.423 0-.752.126-.984.378-.233.247-.35.59-.35 1.03 0 .408.115.735.342.982.227.247.649.499 1.264.756.62.258 1.13.502 1.527.733.398.225.734.482 1.008.772.274.284.483.611.628.982.145.364.217.79.217 1.28 0 .911-.274 1.651-.822 2.22-.548.569-1.302.904-2.264 1.006v1.6h-1.232v-1.592c-1.06-.118-1.882-.507-2.466-1.167-.579-.665-.868-1.548-.868-2.647h2.24c0 .638.145 1.129.435 1.472.294.338.716.507 1.263.507.455 0 .814-.123 1.078-.37.264-.252.395-.59.395-1.014z", fill: "#EDF1F8" }),
        React.createElement("circle", { opacity: 0.2, cx: 38.5, cy: 38.5, r: 38, transform: "rotate(-45 38.5 38.5)", fill: "url(#prefix__paint0_linear_12547:40410)" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "prefix__paint0_linear_12547:40410", x1: 38.5, y1: 38.5, x2: 38.5, y2: 76.5, gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#256CBF", stopOpacity: 0 }),
            React.createElement("stop", { offset: 0, stopColor: "#256CBF" }),
            React.createElement("stop", { offset: 1, stopColor: "#256CBF", stopOpacity: 0 })),
        React.createElement("clipPath", { id: "prefix__clip0_12547:40410" },
            React.createElement("path", { d: "M.5 38c0-20.987 17.013-38 38-38s38 17.013 38 38-17.013 38-38 38S.5 58.987.5 38z", fill: "#fff" })))));
