import * as React from 'react';
import { useContext } from 'react';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartContext } from '../../Cart';
import { SMS as SMSIcon } from '../../../Icons';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getAllSegmentsServicePrice } from '../../../utils';
const Sms = ({ travellers, services, onServiceDelete }) => {
    var _a, _b;
    const smsService = (_b = (_a = services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
        service.subType === GdsServiceVipSubType.SMS);
    const smsServiceIds = smsService.map(service => service.id);
    const cartContext = useContext(CartContext);
    // const onDeleteHandler = (travellerId: string) => {
    // 	onServiceDelete(CartService.SMS, {
    // 		passengerId: travellerId,
    // 		serviceId: smsService[0].id,
    // 		segmentIds: smsService[0].allowedSegments.map(segment => segment.toString())
    // 	});
    // };
    const items = React.useMemo(() => {
        const items = [];
        travellers.forEach(traveller => {
            var _a, _b, _c, _d;
            const travellerName = (_a = cartContext.travellersById.get(traveller.id)) === null || _a === void 0 ? void 0 : _a.name;
            (_d = (_c = (_b = traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.forEach(service => {
                if (smsServiceIds.includes(service.serviceId)) {
                    const gdsService = services.gdsServices.services.find(gdsService => gdsService.id === service.serviceId);
                    items.push({
                        id: `${traveller.id}_${service.serviceId}`,
                        name: travellerName,
                        price: getAllSegmentsServicePrice(gdsService),
                        included: service.confirmedCount === service.count
                        // onDelete: () => onDeleteHandler(traveller.id)
                    });
                }
            });
        });
        return items;
    }, [smsServiceIds, travellers]);
    console.log('debug items', items);
    if (!items.length) {
        return null;
    }
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(ServiceSummaryOption, { groupOption: {
                header: smsService[0].name,
                headerIcon: SMSIcon,
                items
            } })));
};
export default Sms;
