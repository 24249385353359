import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../Input';
import { useTheme } from '../../theme';
import Tooltip from '../../Tooltip';
const Field = ({ name, label, validation, textFieldProps }) => {
    const { input, meta } = useField(name, {
        validate: (value) => (validation ? validation(value || '') : undefined),
        type: 'text'
    });
    const theme = useTheme('Forms').Field;
    return (React.createElement(Tooltip, { title: meta.error || meta.submitError, open: ((meta.modified && meta.touched) || meta.submitFailed) && (!!meta.error || !!meta.submitError) },
        React.createElement("div", { className: theme.input__wrapper },
            React.createElement(Input, Object.assign({}, input, { value: input.value, absoluteHintPosition: true, labelClassName: theme.label, labelShrinkClassName: theme.label_shrink, labelErrorClassName: theme.label_error, inputClassName: theme.input__inner, inputFocusedClassName: theme.input_focused, variant: "outlined", TextFieldProps: Object.assign(Object.assign(Object.assign({}, textFieldProps), input), { label: label, fullWidth: false, value: input.value, onChange: (event) => {
                        input.onChange(event);
                    } }) })))));
};
export default Field;
