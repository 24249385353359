import * as React from 'react';
import QuickSearchFormBase from '@websky/core/src/QuickSearchForm';
import { get } from '@websky/core/src/cache';
import { Currency } from '@websky/core/src/enums';
import { useDefaultCurrency } from '../hooks';
import { IS_FAST_SEARCH } from '../FastSearch/FastSearch';
const QuickSearchForm = props => {
    const { onSubmit } = useDefaultCurrency(Currency.AMD);
    React.useEffect(() => {
        const isFastSearch = get(IS_FAST_SEARCH);
        if (isFastSearch) {
            localStorage.removeItem(IS_FAST_SEARCH);
        }
        else {
            onSubmit(props.parameters, props.SearchFormProps.onSubmit);
        }
    }, []);
    return React.createElement(QuickSearchFormBase, Object.assign({}, props));
};
export default QuickSearchForm;
