import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '../theme';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import Notification from '../Notification';
import { InfoMessagesContext } from './context';
import { filterMessageByPosition } from './utils';
const InfoMessages = props => {
    var _a, _b, _c;
    const { InfoMessages: theme } = useTheme('InfoMessages');
    const messagesFromContext = (_a = useContext(InfoMessagesContext)) === null || _a === void 0 ? void 0 : _a.messages;
    const messages = (_c = ((_b = props.messages) !== null && _b !== void 0 ? _b : messagesFromContext)) === null || _c === void 0 ? void 0 : _c.filter(message => filterMessageByPosition(props.position, message)).filter(({ code }) => code !== 'chamomile');
    if (!(messages === null || messages === void 0 ? void 0 : messages.length)) {
        return null;
    }
    const renderNotification = (message, index) => {
        return (React.createElement(Notification, { renderMessagesWithoutWrapper: props.renderMessagesWithoutWrapper, key: index, title: message.title, image: message.image, status: message.type, infoMessage: message, type: 'context', className: cn(theme.notification, props.itemClassName, {
                [props.itemHtmlClassName]: message.type === InfoMessageTypeEnum.HtmlContent,
                [theme.notification__html]: message.type === InfoMessageTypeEnum.HtmlContent
            }), contentClassName: cn(theme.notification__content, props.contentClassName), htmlClassName: props.htmlClassName }));
    };
    if (props.renderMessagesWithoutWrapper) {
        return React.createElement(React.Fragment, null, messages.map(renderNotification));
    }
    return (React.createElement("div", { className: cn(theme.container, props.className) },
        React.createElement("div", { className: cn(theme.notifications, props.notificationsClassName) }, messages.map(renderNotification))));
};
export default InfoMessages;
