import * as React from 'react';
import { memo } from 'react';
import { Provider } from 'react-redux';
import { useGraphQLClient } from '../../../graphql-client';
import { initI18n } from '../../../utils';
import { createStore } from '../store';
import Checkin from './Checkin/Checkin';
import Analytics from '../../../Engine/components/Analytics';
import { GuardedApolloProvider } from '../../../GuardedApolloProvider';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import ExternalLocaleResources from '../../../ExternalLocaleResources/ExternalLocaleResources';
import IsDemo from '../../../Engine/components/IsDemo';
import AccessDeniedError from '../../../Engine/components/AccessDeniedError';
initI18n('Checkin');
export const Module = memo(() => {
    const client = useGraphQLClient();
    const store = createStore();
    return (React.createElement(GuardedApolloProvider, { client: client },
        React.createElement(Provider, { store: store },
            React.createElement(ModeContext.Provider, { value: Mode.Checkin },
                React.createElement(ExternalLocaleResources, null),
                React.createElement(Analytics, null),
                React.createElement(IsDemo, null),
                React.createElement(AccessDeniedError, null),
                React.createElement(Checkin, null)))));
});
export default Module;
