import { __rest } from "tslib";
import * as React from 'react';
import FastSearchBase from '@websky/core/src/Engine/components/FastSearch/FastSearch';
import { set } from '@websky/core/src/cache';
import { isFastSearchCorrect, fastSearchParse } from '@websky/core/src/Engine/components/FastSearch/utils';
import { Currency } from '@websky/core/src/enums';
import { useDefaultCurrency } from '../hooks';
import { Log } from '@websky/core/src/sentry';
import { useConfig } from '@websky/core/src/context';
export const IS_FAST_SEARCH = 'IS_FAST_SEARCH';
const FastSearch = (_a) => {
    var { startSearch } = _a, props = __rest(_a, ["startSearch"]);
    const url = new URL(location.href);
    const fastSearchString = url.hash.slice(url.hash.lastIndexOf('/') + 1);
    const config = useConfig();
    const passengersConfig = config.SearchForm.passengersConfig;
    const allowPromoCodes = config.global.allowPromoCodes;
    if (isFastSearchCorrect(config, fastSearchString)) {
        Log({
            message: 'get currency from fast search string'
        });
        const searchParams = fastSearchParse(fastSearchString, passengersConfig, allowPromoCodes);
        useDefaultCurrency(searchParams.currency);
    }
    else {
        useDefaultCurrency(Currency.AMD);
    }
    const startSearchHandler = React.useCallback(params => {
        startSearch(params);
        set(IS_FAST_SEARCH, 'true');
    }, [startSearch]);
    return React.createElement(FastSearchBase, Object.assign({}, props, { startSearch: startSearchHandler }));
};
export default FastSearch;
