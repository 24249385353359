import { TravellerFieldEnum } from '@websky/graphql';
import { clearPhone } from '../../BaseComponents/PhoneInput/utils';
import { getAllSegmentsServicePrice, getTotalPrice } from '../../utils';
import { getCurrency } from '../../cache';
export const getAnimalTotalPrice = (services, travellers, withoutUnconfirmed = false) => {
    const selectedServicePrices = [];
    services.forEach(service => {
        const allowedPassengerIds = new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []));
        travellers === null || travellers === void 0 ? void 0 : travellers.forEach(traveller => {
            var _a, _b, _c;
            if (allowedPassengerIds.has(traveller.id)) {
                (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(selectedService => {
                    if (selectedService.serviceId === service.id &&
                        (!withoutUnconfirmed || (withoutUnconfirmed && selectedService.confirmedCount === 0))) {
                        selectedServicePrices.push(Object.assign(Object.assign({}, service.price), { amount: service.price.amount * selectedService.segmentIds.length }));
                    }
                });
            }
        });
    });
    return getTotalPrice(selectedServicePrices, price => price);
};
export const getUpdatedSmsTravellersPhones = (values, dirtyFields) => {
    const travellersValues = [];
    Object.keys(values).forEach(key => {
        if (values[key].selected && dirtyFields[`${key}.phone`]) {
            travellersValues.push({
                id: values[key].id,
                values: [
                    {
                        type: TravellerFieldEnum.Phone,
                        value: clearPhone(values[key].phone),
                        name: 'phone'
                    }
                ]
            });
        }
    });
    return travellersValues;
};
export const getUpdatedSms = (values, smsNotificationService, dirtyFields) => {
    const changedValues = [];
    Object.keys(values).forEach(key => {
        if (dirtyFields[`${key}.selected`]) {
            changedValues.push({
                count: values[key].selected ? 1 : 0,
                passengerId: values[key].id,
                segmentIds: smsNotificationService.allowedSegments.map(segment => segment.toString()),
                serviceId: smsNotificationService.id
            });
        }
    });
    return changedValues;
};
export const getSmsTotalPrice = (services, travellers) => {
    if (!services.length) {
        return {
            amount: 0,
            currency: getCurrency()
        };
    }
    const smsPrice = getAllSegmentsServicePrice(services[0]);
    return getTotalPrice(travellers, traveller => {
        var _a, _b, _c;
        const hasSelectedSms = (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(selectedService => selectedService.serviceId === services[0].id);
        if (hasSelectedSms) {
            return smsPrice;
        }
        return null;
    });
};
