import { format } from 'date-fns';
import { SPECIAL_CATEGORIES } from '../../../SearchForm/utils';
import { AviaPassengerType } from '@websky/graphql';
import { Currency } from '../../../enums';
const PROMO_REGEX = 'PROMO([a-zA-Zа-яА-Я0-9]{1,10})';
const isCurrentCompanySwitchedToAutoGeneration = () => {
    var _a, _b, _c;
    return ['ZZ', 'F7', 'D2'].includes((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
};
export const getIATA = (data) => {
    return data.substr(0, 3);
};
export const isDateGuard = (date) => {
    return !!date.getTime;
};
export const getAbsoluteDate = (isoString) => {
    return isoString.substr(0, 19);
};
export const getDateWithoutTimezone = (date) => {
    return dateConverter(date, false);
};
export const getDateWithTimezone = (date) => {
    return dateConverter(date, true);
};
export const getAirport = (airport) => {
    var _a;
    if ((_a = airport.city) === null || _a === void 0 ? void 0 : _a.name) {
        return Object.assign(Object.assign({}, airport), { name: airport.city.name });
    }
    return airport;
};
const dateConverter = (date, withTimezone) => {
    if (isDateGuard(date)) {
        const dateWithoutTimezone = new Date(withTimezone ? date.getTime() : date.getTime() + date.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
    else {
        const newDate = new Date(date);
        const dateWithoutTimezone = new Date(withTimezone ? newDate.getTime() : newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
};
export const getDate = (data) => {
    const year = data.slice(0, 4);
    const month = data.slice(4, 6);
    const day = data.slice(6, 8);
    return getDateWithoutTimezone(`${year}-${month}-${day}`);
};
export const getPassenger = (data) => {
    const type = data.substr(0, 3), count = parseInt(data.substr(3, 1)), isExtendedPassengerType = SPECIAL_CATEGORIES.includes(type);
    return {
        passengerType: isExtendedPassengerType ? AviaPassengerType.ADT : type,
        extendedPassengerType: isExtendedPassengerType ? type : null,
        count
    };
};
/**
 * Use for smooth transition to regex auto generation
 *
 * @deprecated
 */
const isPassengerNext__deprecated = (data) => {
    return /^(ADT|GCF|CLD|INF|INS|МЛА|SCM|SCF|PDA|YTH|SRC)(.)*\d/.test(data);
};
/**
 * @deprecated
 */
const isFastSearchCorrect__deprecated = (fastSearch) => {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|МЛА|CLD)\d)|((SCF|SCM|YTH|SRC|МЛА)\d)+)((GCF|CLD|INF|INS)\d)*)?((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|UZS|AZN|TRY|RSD|ILS|CNY|GBP)?$/.test(fastSearch);
};
/**
 * @deprecated
 */
const _WZ_isFastSearchCorrect__deprecated = (fastSearch) => {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|PDA|CLD|INF|INS)\d)+)|((SCF|SCM|МЛА|CLD|INF|INS)\d)+)?(PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))?(RUB|USD|KZT)?$/.test(fastSearch);
};
export const isPassengerNext = (passengersConfig, data) => {
    const passengerTypes = passengersConfig.map(passenger => passenger.code).join('|');
    return new RegExp(`^(${passengerTypes})(.)*\\d`).test(data);
};
const isPassengerNextChecker = (passengersConfig, str) => {
    if (isCurrentCompanySwitchedToAutoGeneration()) {
        return isPassengerNext(passengersConfig, str);
    }
    else {
        return isPassengerNext__deprecated(str);
    }
};
export const hasPromo = (value) => {
    return RegExp(PROMO_REGEX).test(value);
};
export const extractPromo = (value) => {
    var _a;
    return (_a = value.match(PROMO_REGEX)) === null || _a === void 0 ? void 0 : _a[1];
};
export const isPromoOrCurrencyNext = (data) => {
    return /^((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|GBP)?$/.test(data);
};
export const extractPromoCodeAndCurrency = (fastSearch) => {
    const matchedResult = fastSearch.match(/((PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|GBP)?$/);
    return {
        promoCode: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[3]) || '',
        payByMiles: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[4]) === 'PAYBYMILES',
        currency: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[5]) || ''
    };
};
export const isPayByMiles = (fastSearch) => /PAYBYMILES$/.test(fastSearch);
export const extractCurrency = (fastSearch) => {
    var _a;
    const matchedResult = fastSearch.match(/(RUB|USD|KZT)$/);
    return ((_a = matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult.slice(-1)) === null || _a === void 0 ? void 0 : _a[0]) || '';
};
export const fastSearchParse = (fastSearch, passengersConfig, allowPromoCodes) => {
    let str = fastSearch;
    const segments = [], passengers = [];
    while (!isPassengerNextChecker(passengersConfig, str) && !isPromoOrCurrencyNext(str) && str.length) {
        const departure = getIATA(str), arrival = getIATA(str.slice(3)), date = getDate(str.slice(6));
        segments.push({
            arrival: {
                iata: arrival
            },
            departure: {
                iata: departure
            },
            date: format(date, 'yyyy-MM-dd')
        });
        str = str.slice(14);
    }
    while (isPassengerNextChecker(passengersConfig, str)) {
        passengers.push(getPassenger(str));
        str = str.slice(4);
    }
    if (!passengers.length) {
        passengers.push({
            passengerType: AviaPassengerType.ADT,
            count: 1
        });
    }
    const result = {
        segments,
        passengers
    };
    const { promoCode, payByMiles, currency } = extractPromoCodeAndCurrency(fastSearch);
    if (allowPromoCodes && promoCode) {
        result.promotionCode = promoCode;
    }
    if (currency) {
        result.currency = currency;
    }
    result.ffpMode = payByMiles;
    return result;
};
export const generateFastSearchRegex = (config) => {
    const airportsAndDatesRegex = `(([A-Z]|[А-Я]){6}\\d{8})+`;
    const passengerTypes = config.SearchForm.passengersConfig.map(passenger => passenger.code).join('|');
    const passengersRegex = `(((${passengerTypes})\\d)+)?`;
    const promo = config.global.allowPromoCodes ? '(PROMO([a-zA-Zа-яА-Я0-9]{1,10}))' : '';
    const payByMiles = config.SearchForm.payByMiles ? '(PAYBYMILES)' : '';
    const promoAndMilesArr = [promo, payByMiles].filter(Boolean);
    const promoAndMilesRegex = promoAndMilesArr.length ? `(${promoAndMilesArr.join('|')})?` : '';
    const currencyRegex = `(${Object.values(Currency).join('|')})?`;
    return new RegExp(`^${airportsAndDatesRegex}${passengersRegex}${promoAndMilesRegex}${currencyRegex}$`);
};
export const isFastSearchCorrect = (config, fastSearch) => {
    var _a;
    if (isCurrentCompanySwitchedToAutoGeneration()) {
        const regex = generateFastSearchRegex(config);
        return regex.test(fastSearch);
    }
    else if (((_a = config.global.companyInfo) === null || _a === void 0 ? void 0 : _a.iataCode) === 'WZ') {
        return _WZ_isFastSearchCorrect__deprecated(fastSearch);
    }
    else {
        return isFastSearchCorrect__deprecated(fastSearch);
    }
};
